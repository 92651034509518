<template>
  <div class="tender-notice-detail container">

    <div class="left_side_box">
      <LeftSide :sideList="sideList" title="公告相关" :isGov="query.isGov"></LeftSide>
    </div>
    <!-- 主体 -->
    <div class="main">
      <!--      <div class="left_side_box">-->
      <!--        <LeftSide :sideList="sideList" title="公告相关" :isGov="query.isGov"></LeftSide>-->
      <!--      </div>-->
      <!-- 标题 -->
      <div class="title">
        <h1>{{ noticeInfo.noticeTitle }}</h1>
        <div class="release-date">
          <span>发布时间：</span>
          <span>{{ noticeInfo.releaseTime|filterDate }}</span>
        </div>
      </div>
      <!-- 招标信息 -->
      <div class="tender-info" style="z-index: 49">
        <div class="item">
          <label>招标名称</label>
          <span>{{ noticeInfo.bidProjectName }}</span>
        </div>
        <div class="item">
          <label>是否为电子标</label>
          <span>{{ bidSectionInfo[0].electronic | filterElectronicType }}</span>
        </div>
        <template v-if="this.type==='change'">
          <div class="item">
            <label>是否变更公告时间</label>
            <span>
              {{ noticeInfo.isChangeTime == '1' ? '是' : '否' }}
            </span>

            <!-- <el-switch
              disabled
              v-model="noticeInfo.isChangeTime"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            ></el-switch> -->
          </div>
        </template>
        <div class="item">
          <label>招标编号</label>
          <span>{{ noticeInfo.bidProjectNo }}</span>
        </div>
        <div class="item">
          <label>代理机构</label>
          <!--          <span style="color: rgba(108, 139, 198, 1)">{{ noticeInfo.agencyName }}</span>-->
          <span><a>{{ noticeInfo.agencyName }}</a></span>
        </div>
        <div class="item">
          <label>招标人</label>
          <!--          <span style="color: rgba(108, 139, 198, 1)">{{ noticeInfo.tenderName }}</span>-->
          <span><a>{{ noticeInfo.tenderName }}</a></span>
        </div>
        <!--        <div class="date_content">-->
        <div class="item">
          <label>招标文件获取开始时间</label>
          <span>{{ noticeInfo.bidDocGetBegin|filterTime }}</span>
        </div>
        <div class="item">
          <label>招标文件获取结束时间</label>
          <span>{{ noticeInfo.bidDocGetEnd|filterTime }}</span>
        </div>
        <div class="item">
          <label>投标文件递交截止时间</label>
          <span>{{ noticeInfo.tenderDocSubmitEnd|filterTime }}</span>
        </div>
        <div class="item">
          <label>招标文件提问截止时间</label>
          <span>{{ noticeInfo.bidDocAskEnd|filterTime }}</span>
        </div>
        <div class="item">
          <label>招标文件答复截止时间</label>
          <span>{{ noticeInfo.bidDocAnswerEnd|filterTime }}</span>
        </div>
        <!--        <div class="item">-->
        <!--          <span @click="drawer = true" style="color: red; cursor: pointer;">查看公告相关的信息</span>-->
        <!--        </div>-->
        <!--        </div>-->
      </div>

      <!-- 内容 -->
      <div class="content">
        <div
          class="content-text"
          v-html="this.type==='notice'? noticeInfo.noticeContent : noticeInfo.changeNoticeContent"
        ></div>
        <FileList :fileList="noticeInfo.noticeFilePath"></FileList>
      </div>

      <!-- 表格 -->
      <el-table v-if="noticeInfo.isChange*1 === 0" :data="bidSectionInfo" style="width: 100%" stripe>
        <el-table-column prop="no" label="序号" width="120"></el-table-column>
        <el-table-column prop="bidNum" label="标段/包件编号"></el-table-column>
        <el-table-column prop="bidName" label="标段/包件名称" width="400"></el-table-column>
        <el-table-column prop="electronic" label="是否电子标" :formatter="formatter"></el-table-column>
        <el-table-column label="操作">
          <a :href="bidderURL+`?noticeId=${query.id}&role=3`" target="_blank" class="bid">
            我要投标
          </a>
        </el-table-column>
      </el-table>
    </div>
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose">
      <LeftSide :sideList="sideList" title="公告相关" :isGov="query.isGov"></LeftSide>
    </el-drawer>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";
import {mapState} from "vuex";
import LeftSide from "@/components/LeftSide/LeftSide";
import FileList from '@/components/FileList/index.vue'
export default {
  components: {
    LeftSide, FileList
  },
  async created() {
    // 获取地址栏传过来的参数
    this.query = this.$route.query;
    this.type = this.$route.query.type;
    // 根据参数获取详情
    let {data} = await tradeInfoApi.findTradeDetail(this.query);
    this.noticeInfo =
      this.type === "notice" ? data.tbNoticeInfo : data.tbChangeNoticeInfo;
    this.noticeInfo.noticeFilePath = JSON.parse(this.noticeInfo.noticeFilePath);
    this.bidSectionInfo = [...data.bidSectionInfos];
    let res = await tradeInfoApi.findTradeDetailAnnouncement({
      id: data.fileInfos[0].id,
      examMethod: this.noticeInfo.examMethod,
      noticeId: data.fileInfos[0].noticeId,
      noticeType: this.type,
    });
    if (res.code == 200) {
      this.sideList = res.data
      this.sideList.map(item => {
        item.createDate = item.createDate && moment(item.createDate).format("YYYY-MM-DD HH:mm:ss")
      })
    }
  },
  data() {
    return {
      drawer: false,
      query: {id: "", type: "", isGov: ""},
      type: "", //判断是 招标公告 还是 变更公告
      noticeInfo: {
        noticeFilePath: []
      },
      bidSectionInfo: [],
      sideList: []
    };
  },
  computed: {
    ...mapState(["baseURL", "bidderURL"])
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterDate(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  },
  methods: {
    formatter(row, column, cellValue) {
      if (cellValue * 1 === 0) {
        return '非电子标'
      } else if (cellValue * 1 === 1) {
        return '电子标'
      }
      return ''
    }
  }
};
</script>

<style lang="less" scoped>
.tender-notice-detail {
  // 主体

  display: flex;

  .left_side_box {
    height: 100%;
    position: sticky;
    top: 100px;
    margin-right: 24px;
    //position: absolute;
    //left: -299px;
    //top: 48px;
  }

  .main {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed rgba(236, 236, 241, 1);
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      .release-date {
        color: #999999;
        font-size: 14px;
      }
    }

    // 招标信息
    .tender-info {
      margin-bottom: 20px;
      margin-top: 24px;
      border: 1px solid rgba(236, 236, 241, 1);
      border-right: 0;
      border-bottom: 0;
      display: grid;
      // grid-template-columns: 206px 370px 206px 370px;
      grid-template-columns: repeat(2 50%);
      position: sticky;
      top: -40px;
      background: #fff;
      box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.05);
      .item {
        font-size: 16px;
        color: rgba(73, 74, 76, 1);
        //display: inline-block;
        display: flex;
        align-items: center;
        line-height: 50px;
        border-bottom: 1px solid rgba(236, 236, 241, 1);
        border-right: 1px solid rgba(236, 236, 241, 1);
        &:first-child {
          grid-column-start: 1;
          grid-column-end: 3;
          label {
            flex: .3;
          }
          span {
            flex: .97 !important;
            display: inline-block;
            width: 100%;
          }
        }

        label {
          width: 100%;
          display: inline-block;
          border-right: 1px dashed rgba(236, 236, 241, 1);
          color: #494A4C;
          font-weight: bold;
          flex: 1;
        }

        span {
          line-height: 20px;
          color: #20201E;
          white-space: wrap;
          flex: 1;
          width: 250px;
          a {
            color: #6C8BC6;
            white-space: wrap;
            width: 80%;
          }
        }

        label, span {
          padding-left: 24px;
        }
      }
    }

    // 内容
    .content {
      margin-bottom: 20px;

      .content-title {
        padding-left: 10px;
        border-left: 5px solid #3660d9;
        margin-bottom: 20px;
      }

      .content-text {
        color: rgba(73, 74, 76, 1);
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 28px;
        width: 100%;
        overflow-x: auto;

        /deep/ section, /deep/ div, /deep/ p {
          line-height: 28px !important;
        }

        /deep/ div {
          margin-bottom: 5px;
        }

        font-family: PingFang SC-Regular, PingFang SC;
      }

      
    }

    // 表格
    .el-table {
      margin: 33px 0;

      /deep/ th {
        background-color: #F4F4F4;
        height: 48px;
        color: #333;
        text-align: left;
        padding-left: 20px;
        font-weight: bold;
      }

      .bid {
        color: rgba(243, 110, 49, 1);

        img {
          width: 16px;
        }
      }

      /deep/ tr {
        height: 40px;

        td {
          padding-left: 20px;
        }

        &:nth-child(odd) {
          // background: #ECECF1;
        }
      }
    }
  }
}
</style>
