<template>
  <div class="left-side">
    <div class="side_title">{{ title }}</div>
    <div :class="['content', moreList ? '' : 'displayScroll']">
      <div class="side_content other_side_item"
           v-for="(item,index) in sideList" :key="item.id">
        <div class="side_item" v-if="index <= 2 || (index > 2 && moreList)">
          <div class="side_content_title" @click="announcement(item)">{{ item.name }}</div>
          <!-- <div class="side_content_date">{{ item.createDate || '' }}</div> -->
        </div>
      </div>
    </div>
    <div v-if="sideList.length === 0" class="nomorl">暂无信息</div>
    <div class="more_content" v-if="sideList.length > 3" @click="displayMoreList">
      <div>更多公告</div>
      <img v-show="!moreList" src="../../assets/images/more_icon.png" alt="">
      <img v-show="moreList" src="../../assets/images/more_icon_close.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSide",
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    sideList: {
      type: Array,
      default: () => {
        return []
      }
    },
    isGov: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      moreList: false
    }
  },
  methods: {
    // 展开更多
    displayMoreList() {
      this.moreList = !this.moreList
    },
    // 进入公告详情
    announcement(obj) {
      let query = {
        id: obj.id,
        type: obj.type,
        isGov: this.isGov
      }
      switch (obj.type) {
        case 'addendum':
          this.goRouter({
              name: "招采信息补遗通知详情", query
            }
          )
          break
        case 'change':
          this.goRouter({
              name: "招采变更信息详情", query
            }
          )
          break
        case 'contract':
          this.goRouter({
              name: "合同公告详情", query
            }
          )
          break
        case 'notice':
          this.goRouter({name: "招采信息详情", query})
          break
        case 'exception':
          this.goRouter({name: "招采信息异常公告详情", query})
          break
        default:
          break
      }
      if (obj.type == '1' || obj.type == '2' || obj.type == '3' || obj.type == '4' || obj.type == '5') {
        this.goRouter({name: "招采信息结果公示详情", query})
      }
      // this.$router.push(`/home/notice-detail?informNoticeId=${id}`)
    },
    goRouter(url) {
      let routeData = this.$router.resolve({name: url.name, query: url.query})
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.left-side {
  margin-top: 24px;
  width: 250px;
  background: #FBFBFC;
  padding: 0 12px 10px 16px;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
  border-radius: 12px 12px 12px 12px;
  background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
  border: 1px solid #FFFFFF;
  //position: sticky;
  //top: 100px;

  .side_item {
    box-sizing: border-box;
    border-bottom: 1px dashed #DEDEDE;
  }

  .other_side_item {
    margin-bottom: 14px;
  }

  .nomorl {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    color: #97979D;
  }
  .last_side_item {
    border: none;
    margin-bottom: 8px;
  }


  .side_title {
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    padding: 14px 0;
  }

  .displayScroll {
    .content::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    max-height: 60vh;
    background: #FBFBFC;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      height: 1px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 10px;
      background: #efefef;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    .side_content {
      font-weight: 400;
      font-family: Segoe UI-Regular, Segoe UI;

      .side_content_title {
        padding-left: 14px;
        font-size: 14px;
        color: #20201E;
        margin-bottom: 10px;
        line-height: 21px;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 指定要显示的行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        transition: color 0.3s;
        &:hover {
          color: #F36E31;
        }
        &::after {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: #F36E31;
          left: 0;
          top: 9px;
        }     
      }

      .side_content_date {
        font-size: 12px;
        color: #97979D;
      }
    }
  }

  .more_content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #898A8D;
    // padding: 7px 0 24px 0;

    img {
      width: 12px;
      height: 11px;
      margin-left: 8px;
    }
  }

}
</style>
